<template>
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_961_65820"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="24"
    >
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="url(#paint0_linear_961_65820)"
      />
    </mask>
    <g mask="url(#mask0_961_65820)">
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="url(#paint1_linear_961_65820)"
      />
      <g opacity="0.82" filter="url(#filter0_f_961_65820)">
        <g style="mix-blend-mode: darken" opacity="0.82">
          <path
            d="M16.6175 11.6104H3.5293V18.1558H16.6175V11.6104Z"
            fill="#0F7D56"
            fill-opacity="0.27"
          />
        </g>
      </g>
      <g filter="url(#filter1_d_961_65820)">
        <path
          d="M20 7.94805H14.1176C13.2243 7.94805 12.5 7.18054 12.5 6.23377V0L20 7.94805Z"
          fill="url(#paint2_linear_961_65820)"
        />
      </g>
      <path
        d="M4.62109 13.2051L5.4209 11.7344H6.43164L5.18945 13.8496L6.46387 16H5.44141L4.62109 14.5059L3.80078 16H2.77832L4.05273 13.8496L2.81055 11.7344H3.82129L4.62109 13.2051ZM7.78809 15.2939H9.6543V16H6.90918V11.7344H7.78809V15.2939ZM12.373 14.8809C12.373 14.7148 12.3145 14.5879 12.1973 14.5C12.0801 14.4102 11.8691 14.3164 11.5645 14.2188C11.2598 14.1191 11.0186 14.0215 10.8408 13.9258C10.3564 13.6641 10.1143 13.3115 10.1143 12.8682C10.1143 12.6377 10.1787 12.4326 10.3076 12.2529C10.4385 12.0713 10.625 11.9297 10.8672 11.8281C11.1113 11.7266 11.3848 11.6758 11.6875 11.6758C11.9922 11.6758 12.2637 11.7314 12.502 11.8428C12.7402 11.9521 12.9248 12.1074 13.0557 12.3086C13.1885 12.5098 13.2549 12.7383 13.2549 12.9941H12.376C12.376 12.7988 12.3145 12.6475 12.1914 12.54C12.0684 12.4307 11.8955 12.376 11.6729 12.376C11.458 12.376 11.291 12.4219 11.1719 12.5137C11.0527 12.6035 10.9932 12.7227 10.9932 12.8711C10.9932 13.0098 11.0625 13.126 11.2012 13.2197C11.3418 13.3135 11.5479 13.4014 11.8193 13.4834C12.3193 13.6338 12.6836 13.8203 12.9121 14.043C13.1406 14.2656 13.2549 14.543 13.2549 14.875C13.2549 15.2441 13.1152 15.5342 12.8359 15.7451C12.5566 15.9541 12.1807 16.0586 11.708 16.0586C11.3799 16.0586 11.0811 15.999 10.8115 15.8799C10.542 15.7588 10.3359 15.5938 10.1934 15.3848C10.0527 15.1758 9.98242 14.9336 9.98242 14.6582H10.8643C10.8643 15.1289 11.1455 15.3643 11.708 15.3643C11.917 15.3643 12.0801 15.3223 12.1973 15.2383C12.3145 15.1523 12.373 15.0332 12.373 14.8809ZM15.3789 13.2051L16.1787 11.7344H17.1895L15.9473 13.8496L17.2217 16H16.1992L15.3789 14.5059L14.5586 16H13.5361L14.8105 13.8496L13.5684 11.7344H14.5791L15.3789 13.2051Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_961_65820"
        x="-35.4707"
        y="-27.3896"
        width="91.0881"
        height="84.5454"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="19.5" result="effect1_foregroundBlur_961_65820" />
      </filter>
      <filter
        id="filter1_d_961_65820"
        x="-10.5"
        y="-24"
        width="57.5"
        height="57.948"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="1" />
        <feGaussianBlur stdDeviation="12.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_961_65820" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_961_65820"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_961_65820"
        x1="10"
        y1="0"
        x2="10"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF7979" />
        <stop offset="1" stop-color="#E85555" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_961_65820"
        x1="10"
        y1="0"
        x2="10"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#61A850" />
        <stop offset="0.630208" stop-color="#296A17" />
        <stop offset="1" stop-color="#296A17" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_961_65820"
        x1="16.25"
        y1="0"
        x2="16.25"
        y2="7.94805"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000265127" stop-color="#83CC70" />
        <stop offset="1" stop-color="#61A850" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "AI",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
</style>
